import React from "react";
import {
    Box,
    Grid,
    Avatar,
    Typography,
    makeStyles,
    Link,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faPhone,
    faMapMarkerAlt,
    faGraduationCap,
    faCode,
    faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: "#f7f7f7",
        minHeight: "100vh",
        paddingTop: theme.spacing(6),
    },
    section: {
        backgroundColor: "#fff",
        padding: theme.spacing(4),
        marginBottom: theme.spacing(4),
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
    },
    sectionHeader: {
        color: "#005b99",
        fontWeight: "bold",
        marginBottom: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: theme.spacing(1),
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginBottom: theme.spacing(4),
    },
    name: {
        fontWeight: "bold",
        fontSize: "2.5rem",
        marginBottom: theme.spacing(2),
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.5rem",
        color: "#005b99",
        marginBottom: theme.spacing(2),
    },
    contact: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        "& svg": {
            marginRight: theme.spacing(1),
            color: "#005b99",
        },
    },
    skill: {
        backgroundColor: "#e0e0e0",
        padding: theme.spacing(1),
        borderRadius: "5px",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "inline-block",
    },
    skillTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(1),
    },
    skillList: {
        listStyle: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        flexWrap: "wrap",
    },
    skillItem: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const programmingLanguages = [
    {
        name: "HTML5"
    },
    {
        name: "CSS3"
    },
    {
        name: "JavaScript"
    },
    {
        name: "Typescript"
    },
    {
        name: "PHP"
    }
];

const libraries = [
    {
        name: "React"
    },
    {
        name: "Next JS"
    },
    {
        name: "Boostrap"
    },
    {
        name: "Material-UI"
    },
    {
        name: "Symfony"
    },
    {
        name: "Figma"
    },
    {
        name: "Axios"
    },
    {
        name: "Redux"
    }
];

function CV() {
    const classes = useStyles();

    return (
        <Box className={classes.main}>
            <Grid container justify="center">
                <Grid item md={10} lg={8}>
                    <Box className={classes.section}>
                        <Avatar
                            alt="Zdjęcie"
                            src={"me.jpg"}
                            className={classes.avatar}
                        />
                        <Typography variant="h1" className={classes.name}>
                            Arkadiusz Nowik
                        </Typography>
                        <Typography variant="h3" className={classes.title}>
                            Frontend Developer
                        </Typography>
                        <Box className={classes.contact}>
                            <FontAwesomeIcon icon={faEnvelope} className={classes.icon}/>
                            <Typography variant="body1">
                                <Link href="mailto:codyispro@gmail.com">
                                    codyispro@gmail.com
                                </Link>
                            </Typography>
                        </Box>
                        <Box className={classes.contact}>
                            <FontAwesomeIcon icon={faPhone} className={classes.icon}/>
                            <Typography variant="body1">(+48) 609 461 653</Typography>
                        </Box>
                        <Box className={classes.contact}>
                            <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                className={classes.icon}
                            />
                            <Typography variant="body1">Szczecin, Polska</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1">
                                Chętnie podejmę się pracy jako frontend developer w firmie z
                                branży IT. Posiadam 8-letnie doświadczenie w projektowaniu i
                                tworzeniu responsywnych stron internetowych przy użyciu
                                najnowszych technologii.
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.section}>
                        <Typography variant="h4" className={classes.sectionHeader}>
                            <FontAwesomeIcon icon={faCode}/>
                            Umiejętności
                        </Typography>
                        <Box>
                            <Typography variant="h5" className={classes.skillTitle}>
                                Języki programowania:
                            </Typography>
                            <ul className={classes.skillList}>
                                {programmingLanguages.map((language) => (
                                    <li className={classes.skillItem}>
                                        <Box className={classes.skill}>{language.name}</Box>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                        <Box>
                            <Typography variant="h5" className={classes.skillTitle}>
                                Frameworki i biblioteki:
                            </Typography>
                            <ul className={classes.skillList}>
                                {libraries.map((library) => (
                                    <li className={classes.skillItem}>
                                        <Box className={classes.skill}>{library.name}</Box>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                    </Box>

                    <Box className={classes.section}>
                        <Typography variant="h4" className={classes.sectionHeader}>
                            <FontAwesomeIcon icon={faLaptopCode}/>
                            Doświadczenie
                        </Typography>
                        <Box>
                            <Typography variant="h5">
                                Developer, Ignition-Interactive
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                Wrzesień 2013 - obecnie
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                - Projektowanie i tworzenie responsywnych interfejsów
                                użytkownika w oparciu o framework React
                                <br/>
                                - Tworzenie wydajnego i skalowalnego kodu
                                <br/>
                                - Współpraca z zespołem  w celu osiągnięcia celów projektowych
                                <br/>
                                - Praca w metodologii Kanban, Scrum
                                <br/>
                                - Projektowanie produktów typu White-label (Wynoszenie projektu graficznego do komponentów w Figmie,
                                przygotowywanie zmiennych i typografii, przygotowywanie eksportów w taki sposób żeby nowy White-label
                                był gotowy po wyeksportowaniu grafik i zmiennych w figmie)
                                <br/>
                            </Typography>
                        </Box>
                        <br/><br/>
                        <Box>
                            <Typography variant="h5">
                                Własna działalność
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                Wrzesień 2013 - obecnie
                            </Typography>
                            <br/>
                            <Typography variant="body1">
                                - Tworzenie stron internetowych opartych o Wordpress
                                <br/>
                                - Tworzenie stron sklepów internetowych
                                <br/>
                                - Integracja sklepów z API hurtowni (automatyczne wysyłanie zamówień, aktualizacja stanów magazynowych,
                                aktualizacja cen, pobieranie danych o częściach samochodowych  przez API TecDOC)
                                <br/>
                                - Proste aplikacje w React native ( formularz, skaner kodów ean, drukowanie etykiet )
                                <br/>
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.section}>
                        <Typography variant="h4" className={classes.sectionHeader}>
                            <FontAwesomeIcon icon={faGraduationCap}/>
                            Edukacja
                        </Typography>
                        <Box>
                            <Typography variant="h5">
                                Zachodniopomorski Uniwersytet Technologiczny w Szczecinie, Informatyka
                            </Typography>
                            <br/>
                            <Typography variant="body1">Październik 2010 - Wrzesień 2014</Typography>
                            <br/>
                            <Typography variant="body1">
                                - Część przedmiotów realizowana w języku angielskim
                                <br/>
                                - Udział w wielu projektach zespołowych
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
)
}

export default CV
