
import './App.css';
import CV from "./CV";

function App() {
  return (
    <div className="App">
      <CV/>
    </div>
  );
}

export default App;
